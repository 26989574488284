import React from 'react';
import styled from 'styled-components';
import Raccoon from '../assets/raccoon.png';
import {useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';


const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 30px; /* Add some padding for better spacing on mobile */

  @media (max-width: 768px) {
    padding: 20px; /* Adjust padding for smaller screens */
  }
`;


const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2rem; /* Smaller font size for mobile */
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.5rem; 
    text-align: center;/* Smaller font size for mobile */
  }
`;

const Image = styled.img`
  width: 50%; /* Adjust the width as needed */

  @media (max-width: 768px) {
    width: 80%; /* Wider width for mobile */
  }
`;

const RecycleButton = styled.button`
  padding: 15px 30px;
  font-size: 1.2rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }

  @media (max-width: 768px) {
    padding: 12px 25px; /* Adjust padding for mobile */
    font-size: 1rem; /* Smaller font size for mobile */
  }
`;

function OrderConfirm({CurrentUser, onSessionId}) {
    
  const navigate = useNavigate();

const handleBackToHome = () => {
  // Navigate back to the home page
  navigate('/');
};

const location = useLocation();
const [fetchTriggered, setFetchTriggered] = useState(false);

useEffect(() => {
  const query = new URLSearchParams(location.search);
  const sessionId = query.get('session_id');
  console.log('Session ID:', sessionId);
  
  if (sessionId) {
    onSessionId(sessionId);
  } else {
    console.error('No session ID found in URL');
  }
}, [location.search, onSessionId]);

    return (
        <PageContainer>
            <Title>Your Order is confirmed</Title>
            <Subtitle>Thank you for your Trash! You'll receive an email with your shipping label shortly.</Subtitle>
           
            <Image src={Raccoon} alt="Raccoon" />
            
            <RecycleButton onClick={handleBackToHome}>Back to home</RecycleButton>
            
        </PageContainer>
    );
}

export default OrderConfirm;
